<template>
    <transition name="slide-fade">
        <div v-if="visible" class="notification-banner">
            {{ message || 'Success' }}

            <img @click="closeBanner" class="close" :src="require('@/assets/img/close.svg')" alt="close">
        </div>
    </transition>
</template>

<script>
import { mapState } from 'vuex'

export default {
    props: {
		visible: Boolean
	},

    watch: {
		visible (val) {
			if (!val) return
			if (this.timeout) clearTimeout(this.timeout)
			this.timeout = setTimeout(() => {
				this.closeBanner()
			}, 3000)
		}
	},

    computed: {
		// title () {
		// 	const titles = {
		// 		success: 'Success',
		// 		warning: 'Watch Out!',
		// 		failed: 'Sorry, something went wrong.',
		// 		invalid: 'Action invalid!'
		// 	}

		// 	return titles[this.type]
		// },

		// isTypeSuccess () {
		// 	return this.type === 'success'
		// },

		// isTypeWarning () {
		// 	return this.type === 'warning'
		// },

		// isTypeError () {
		// 	return this.type === 'invalid' || this.type === 'failed'
		// },

		...mapState({
			message: state => state.banner.message,
			// type: state => state.banner.type
		})
	},

    methods: {
		closeBanner () {
			this.$store.commit('TOGGLE_BANNER', { status: false })
		}
	}
}
</script>

<style lang="scss" scoped>
.notification-banner {
    position: fixed;
	display: inline-flex;
	align-items: center;
	background-color: #45B171;
	box-shadow: 0 -3px 6px #00000047;
	color: #FFFFFF;
	border-radius: 3px;
	bottom: 2em;
	left: 2em;
	z-index: 999999+1;
    padding: 15px 20px;
    font-size: 14px;

    .close {
        width: 11px;
        height: 11px;
        margin-left: 20px;
        cursor: pointer;
    }
}

.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: all .5s ease;
  opacity: 1;
}

.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
</style>